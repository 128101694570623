import styled from 'styled-components'

type NavigationBarStickyContainerProps = {
  isVisible: boolean
  backgroundColor: string
  verticalPadding: boolean
  bottomBorder: boolean
}

export const NavigationBarStickyContainer = styled.div<NavigationBarStickyContainerProps>`
  background-color: ${(props) => props.isVisible && props.backgroundColor};
  z-index: ${(props) => props.isVisible && 2};
  position: ${(props) => props.isVisible && 'fixed'};
  top: ${(props) => props.isVisible && 0};
  right: ${(props) => props.isVisible && 0};
  left: ${(props) => props.isVisible && 0};
  width: ${(props) => props.isVisible && '100%'};
  flex-direction: ${(props) => props.isVisible && 'row'};
  display: ${(props) => props.isVisible && 'flex'};
  padding: ${(props) => props.isVisible && props.verticalPadding && '16px 0'};
  border-bottom: ${(props) => props.isVisible && props.verticalPadding && props.bottomBorder && '1px solid #C9C8C8'};
`

export const NavigationBarContainer = styled.div<NavigationBarStickyContainerProps>`
  width: ${(props) => props.isVisible && '100%'};
`
