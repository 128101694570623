import { useEffect, useState, useRef } from 'react'
import { NavigationBar as AlliumNavigationBar, Spacer } from '@telus-uds/components-web'
import { SpacerProps } from '../Spacer/Spacer'
import useKoodoAnalyticsEvent from '../../../../hooks/useKoodoAnalyticsEvent'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import { NavigationBarStickyContainer, NavigationBarContainer } from './Styles'

export type NavigationBarProps = {
  heading?: string
  headingLevel?: string
  items: NavigationBarItemProps[]
  selectedId: string
  spacer?: SpacerProps
  isNavigationSticky?: boolean
  stickyNavigationBackgroundColor?: string
  stickyNavigationVerticalPadding?: boolean
  stickyNavigationBottomBorder?: boolean
}

type NavigationBarItemProps = {
  id: string
  label: string
  href?: string
  analyticsEvent?: ContentfulAnalyticsEventType
  items?: NavigationBarSubItemProps[]
  onClick?: () => void
}

type NavigationBarSubItemProps = {
  id: string
  label: string
  href: string
  analyticsEvent?: ContentfulAnalyticsEventType
  onClick?: () => void
}

const NavigationBar = ({
  heading,
  headingLevel,
  items,
  selectedId,
  spacer,
  isNavigationSticky = false,
  stickyNavigationBackgroundColor = 'athens_grey',
  stickyNavigationVerticalPadding = false,
  stickyNavigationBottomBorder = false,
}: NavigationBarProps) => {
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = useState(false)
  const [idSelected, setIdSelected] = useState<string>(selectedId)
  const [analytics, setAnalytics] = useState<NavigationBarSubItemProps['analyticsEvent']>(null)
  const [isInView, setIsInView] = useState(true)
  const navBarRef = useRef<HTMLDivElement>(null)

  useKoodoAnalyticsEvent(analytics, isAnalyticsTriggered)

  useEffect(() => setIsAnalyticsTriggered(true), [analytics])

  useEffect(() => {
    items?.forEach((item) => {
      item.onClick = () => setAnalytics(item.analyticsEvent)

      item.items?.forEach((subItem) => (subItem.onClick = () => setAnalytics(subItem.analyticsEvent)))
    })
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(!entry.isIntersecting)
      },
      { threshold: 1 }
    )

    if (navBarRef.current) {
      observer.observe(navBarRef.current)
    }
  }, [])

  useEffect(() => {
    //! If the page reloads, get the hash from the URL and set the selectedId
    const hash = window?.location?.hash
    if (hash) {
      items?.forEach((item) => {
        if (item.href === hash) {
          setIdSelected(item.id)
        }
      })
    }
  }, [])

  const onChangeSelectedId = (id: string) => setIdSelected(id)

  // TODO: We need to look into UDS adding the stick navigation into Navigartion Bar to avoid creating complexity to this component
  const backgroundColor = stickyNavigationBackgroundColor === 'athens_grey' ? '#f4f4f7' : '#ffffff'

  return (
    <>
      <div data-testid="navigation-bar-testid" ref={navBarRef}>
        {renderSpacer(spacer)}
        <AlliumNavigationBar
          heading={heading}
          headingLevel={headingLevel}
          items={items}
          selectedId={idSelected}
          onChange={onChangeSelectedId}
        />
      </div>

      {isInView && isNavigationSticky && (
        <NavigationBarStickyContainer
          isVisible={isInView}
          backgroundColor={backgroundColor}
          verticalPadding={stickyNavigationVerticalPadding}
          bottomBorder={stickyNavigationBottomBorder}
          data-testid="sticky-navigation-bar-testid"
        >
          <Spacer space={{ xs: 3, lg: 11 }} direction="row" />
          <NavigationBarContainer
            isVisible={isInView}
            backgroundColor={backgroundColor}
            verticalPadding={stickyNavigationVerticalPadding}
            bottomBorder={stickyNavigationBottomBorder}
          >
            {renderSpacer(spacer)}
            <AlliumNavigationBar
              heading={heading}
              headingLevel={headingLevel}
              items={items}
              selectedId={idSelected}
              onChange={onChangeSelectedId}
            />
          </NavigationBarContainer>
          <Spacer space={{ xs: 3, lg: 11 }} direction="row" />
        </NavigationBarStickyContainer>
      )}
    </>
  )
}

export default NavigationBar
