import { INavigationBarFields } from 'contentful-types'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import NavigationBar from './NavigationBar'

const ContentfulNavigationBar = withContentful<INavigationBarFields>(NavigationBar, {
  heading: (props) => (props.fields.heading ? props.fields.heading : ''),
  headingLevel: (props) => (props.fields.headingLevel ? props.fields.headingLevel : ''),
  items: (props) =>
    props.fields.item
      .filter((item) => item && item.fields)
      .map((item) => {
        return {
          id: item.sys.id,
          label: item.fields.text,
          href: item.fields.url ? item.fields.url : '',
          analyticsEvent: item.fields.linkAnalyticsEvent,
          items: item.fields.item
            ?.filter((subItem) => subItem && subItem.fields)
            .map((subItem) => {
              return {
                id: subItem.sys.id,
                label: subItem.fields.text,
                href: subItem.fields.url,
                analyticsEvent: subItem.fields.linkAnalyticsEvent,
              }
            }),
        }
      }),
  selectedId: (props) => (props.fields.selectedItem ? props.fields.selectedItem.sys.id : ''),
  spacer: mapSpacer,
  isNavigationSticky: (props) => props.fields.isNavigationSticky,
  stickyNavigationBackgroundColor: (props) =>
    props.fields.stickyNavigationBackgroundColor.split(' ').join('_').toLowerCase(),
  stickyNavigationVerticalPadding: (props) => props.fields.stickyNavigationVerticalPadding,
  stickyNavigationBottomBorder: (props) => props.fields.stickyNavigationBottomBorder,
})

export default ContentfulNavigationBar
